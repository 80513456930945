import React, { useState, useEffect } from 'react';
import '../sass/BingoTable.css';
import axios from 'axios';

const BingoTable = ({ previousCallList, cardNumber, selectedPattern }) => {
    const [columnValues, setColumnValues] = useState([]);
    const [matchedValues, setMatchedValues] = useState([0, 'free', 'FREE']);
    const [tableData, setTableData] = useState([]);
    const [matchedState, setMatchedState] = useState({
        rows: Array(5).fill(false),
        cols: Array(5).fill(false),
        diagonal1: false,
        diagonal2: false,
        corners: {
            topLeft: false,
            topRight: false,
            bottomLeft: false,
            bottomRight: false,
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage

            try {
                const response = await axios.get('https://hamsterbingo.com/api2/fetch_result_data.php', {
                    params: { userId, cardNumber },
                });
                if (response.data.isregistered) {
                    const fetchedColumnValues = response.data.columnValues.map((col) => [...col]);

                    // Set the center cell to 'Free' if the data structure is 5x5
                    if (fetchedColumnValues.length === 5 && fetchedColumnValues[2].length === 5) {
                        fetchedColumnValues[2][2] = 'free';
                    }
                    setColumnValues(fetchedColumnValues);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, [cardNumber]);

    useEffect(() => {
      const matchedNumbers = previousCallList.map(item => String(item.number));
      const combinedMatchedValues = [0, 'free', 'FREE', ...matchedNumbers];
      setMatchedValues(combinedMatchedValues);
  
      // Initialize updated states
      const updatedRows = Array(5).fill(true);
      const updatedCols = Array(5).fill(true);
      const updatedCorners = { ...matchedState.corners };
      const diagonal1Matches = Array(5).fill(false);
      const diagonal2Matches = Array(5).fill(false);
  
      // Transpose columnValues to rows for processing
      const transposedValues = Array.from({ length: 5 }, (_, rowIndex) =>
          columnValues.map(col => col[rowIndex])
      );
  
      // Process each cell for matching
      const updatedTable = transposedValues.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
              const isMatched = combinedMatchedValues.includes(cell);
  
              if (!isMatched) updatedRows[rowIndex] = false;
              if (!isMatched) updatedCols[colIndex] = false;
  
              if (rowIndex === colIndex && isMatched) diagonal1Matches[rowIndex] = true;
              if (rowIndex + colIndex === 4 && isMatched) diagonal2Matches[rowIndex] = true;
  
              if (rowIndex === 0 && colIndex === 0 && isMatched) updatedCorners.topLeft = true;
              if (rowIndex === 0 && colIndex === 4 && isMatched) updatedCorners.topRight = true;
              if (rowIndex === 4 && colIndex === 0 && isMatched) updatedCorners.bottomLeft = true;
              if (rowIndex === 4 && colIndex === 4 && isMatched) updatedCorners.bottomRight = true;
  
              return { cell, isMatched };
          })
      );
  
      // Update state with new values
      setMatchedState({
          rows: updatedRows,
          cols: updatedCols,
          diagonal1: diagonal1Matches.every(Boolean),
          diagonal2: diagonal2Matches.every(Boolean),
          corners: updatedCorners,
      });
  
      setTableData(updatedTable);
  }, [columnValues, previousCallList]);
  
    useEffect(() => {
        if (columnValues.length > 0) {
            checkPatternAndPlayAudio();
        }
    }, [matchedState, selectedPattern]);

    const checkPatternAndPlayAudio = () => {
        const { rows, cols, diagonal1, diagonal2, corners } = matchedState;
        let patternMatched = false;
        const pattern = parseInt(selectedPattern, 10);

        switch (pattern) {
            case 1: // Any one line
                patternMatched =
                    rows.includes(true) ||
                    cols.includes(true) ||
                    diagonal1 ||
                    diagonal2 ||
                    (corners.topLeft && corners.topRight && corners.bottomLeft && corners.bottomRight);
                break;

            case 2: // Any two lines
                const matchedLineCount =
                    rows.filter(Boolean).length +
                    cols.filter(Boolean).length +
                    (diagonal1 ? 1 : 0) +
                    (diagonal2 ? 1 : 0);
                patternMatched = matchedLineCount >= 2;
                break;

            case 3: // Any vertical line
                patternMatched = cols.includes(true);
                break;

            case 4: // Any horizontal line
                patternMatched = rows.includes(true);
                break;

            case 5: // T pattern
                patternMatched = rows[0] && cols[2];
                break;

            case 6: // Reverse T pattern
                patternMatched = rows[4] && cols[2];
                break;

            case 7: // X pattern
                patternMatched = diagonal1 && diagonal2;
                break;

            case 8: // L pattern
                patternMatched = cols[0] && rows[4];
                break;

            case 9: // Reverse L pattern
                patternMatched = cols[4] && rows[4];
                break;

            case 10: // Half above
                patternMatched = rows[0] && rows[1] && rows[2];
                break;

            case 11: // Half below
                patternMatched = rows[2] && rows[3] && rows[4];
                break;

            case 12: // Full pattern
                patternMatched = rows.every(Boolean);
                break;

            case 13: // Half left
                patternMatched = cols[0] && cols[1];
                break;

            default:
                break;
        }

        if (patternMatched) {
            console.log('Playing bingo audio');
            const audio = new Audio('fana wend/good.wav');
            audio.play();
        } else {
            console.log('Playing not bingo audio');
            const audio = new Audio('fana wend/notgood.wav');
            audio.play();
        }
    };

    return (
      <div>
          <h4 style={{ fontSize: 42, fontWeight: 'bolder', textAlign: 'center', backgroundColor: '#0047BB', color: 'white', marginTop: '-30px' }}>
              Card No: {cardNumber}
          </h4>
          <table style={{ width: '96%', height: '450px', marginLeft: '2%', position: 'relative', textAlign: 'center', marginTop: '-40px' }}>
              <thead>
                  <tr>
                      <th className='b'>B</th>
                      <th className='i'>I</th>
                      <th className='n'>N</th>
                      <th className='g'>G</th>
                      <th className='o'>O</th>
                  </tr>
              </thead>
              <tbody>
              {tableData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cellData, colIndex) => {
                              const isCorner =
                                  (rowIndex === 0 && colIndex === 0 && matchedState.corners.topLeft) ||
                                  (rowIndex === 0 && colIndex === 4 && matchedState.corners.topRight) ||
                                  (rowIndex === 4 && colIndex === 0 && matchedState.corners.bottomLeft) ||
                                  (rowIndex === 4 && colIndex === 4 && matchedState.corners.bottomRight);

                              const isRowMatched = matchedState.rows[rowIndex];
                              const isColMatched = matchedState.cols[colIndex];
                              const isDiag1Matched = matchedState.diagonal1 && rowIndex === colIndex;
                              const isDiag2Matched = matchedState.diagonal2 && rowIndex + colIndex === 4;

                              let cellClass = '';
                              if (isCorner && matchedState.corners.topLeft && matchedState.corners.topRight && matchedState.corners.bottomLeft && matchedState.corners.bottomRight) {
                                  cellClass = 'matched-corner';
                              } else if (isRowMatched) {
                                  cellClass = 'matched-row';
                              } else if (isColMatched) {
                                  cellClass = 'matched-col';
                              } else if (isDiag1Matched || isDiag2Matched) {
                                  cellClass = 'matched-diag';
                              } else if (cellData.isMatched) {
                                  cellClass = 'matched';
                              }

                              return (
                                  <td
                                      key={`${rowIndex}-${colIndex}`}
                                      className={`${cellClass} ${cellData.cell === 'free' ? 'free' : ''}`}
                                  >
                                      {cellData.cell === 'free' ? 'free' : cellData.cell}
                                  </td>
                              );
                          })}
                      </tr>
                  ))}
              </tbody>
          </table>
      </div>
  );
};

export default BingoTable;
