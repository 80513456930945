import React, { useState, useEffect } from 'react';
import "../sass/report.scss";
import { Link } from "react-router-dom";
import Header from './Header'; // Import the Header component

const Report = () => {

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [fromDate, setFromDate] = useState(''); // From date
  const [frequency, setFrequency] = useState(1); // Default to "Daily"
  const [data, setData] = useState(null); // For storing report data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchReport = async () => {
    setLoading(true);
    setError('');
    setData(null); // Clear previous data

    const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage

    if (!userId) {
      setError('User ID not found. Please ensure you are logged in.');
      setLoading(false);
      return;
    }

    const url = `https://hamsterbingo.com/api2/report.php?id=${userId}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          date_r: date,
          date_t: fromDate || '',
          ss: frequency,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data. Please try again later.');
      }

      const result = await response.json();

      if (result.error) {
        throw new Error(result.error);
      }

      setData(result); // Set the fetched data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []); // Empty dependency array to run only once

  return (
    <div>
      <Header /> {/* Use the Header component */}
      <h1>Generate Report/<Link to="/control/" style={{ color: '#f0f0f0' }}>/<span style={{ color: 'green' }}>Detail Report</span></Link></h1>
      <div className="report-container">
        <form
          className="report-form"
          onSubmit={(e) => {
            e.preventDefault();
            fetchReport();
          }}
        >
          <div className="form-group">
            <label htmlFor="fromDate">From Date:</label>
            <input
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="date">To Date:</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="frequency">Select Frequency:</label>
            <select
              id="frequency"
              value={frequency}
              onChange={(e) => setFrequency(Number(e.target.value))} // Convert to number
            >
              <option value={1}>Daily</option>
              <option value={2}>Weekly</option>
              <option value={3}>Monthly</option>
              <option value={4}>Yearly</option>
              <option value={5}>Lifetime</option>
            </select>
          </div>

          <button type="submit" disabled={loading} className='submit-btn '>
            {loading ? 'Loading...' : 'Fetch Report'}
          </button>
        </form>

        {loading && <p>Loading...</p>}
        {error && <p style={{ color: 'red' }}>Error: {error}</p>}

        {data && (
          <div>
            <h2>User Details</h2>
            <table className="report-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>City</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.user.id}</td>
                  <td>{data.user.username}</td>
                  <td>{data.user.city}</td>
                  <td>{data.user.address}</td>
                  <td>{data.user.phone}</td>
                  <td>{data.user.balance}</td>
                </tr>
              </tbody>
            </table>

            <h2>Report Details</h2>
            <table className="report-table">
              <thead>
                <tr>
                  <th>Income</th>
                  <th>Percent</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.report.income}</td>
                  <td>{data.report.percent * 100}%</td>
                  <td>{data.report.commission}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Report;
