
import React, { Component } from "react";
import Slider from "rc-slider";
import Select from "react-select";
import axios from 'axios';
// Custom Components
import BingoBoard from "./subcomponents/BingoBoard.js";
import Mypattern from "./mypattern.js";
import CallHistory from "./subcomponents/CallHistory.js";
import VoiceSelector from "./subcomponents/selectvoice.js";
import BingoTable from "../components/bingotable.js";
import BingoTable1 from "../components/bingotable2.js";
import { openDB } from 'idb';
// Utilities
import { generateBingoBoard, getRandomBingoNumber, getPresetPatterns, getBallDisplay, getLogoBallDisplay } from "../utils.js";

// Chimes
import {
	chime1,
	chime2,
	chime3,
	chime4,
	chime5,
	chime6,
	chime7,
	chime8,
	chime9,
	chime10,
	chime11,
	chime12,
	pop1,
	pop2,
	pop3,
	pop4,
	shuffle,
} from "../chimes";
class BingoGame extends Component {
	constructor(props) {
		super(props);
		// -------------------------- Set properties ----- //
		// Balls display pieces
		this.dbPromise = this.initDB();
		this.totalBallsCalled = 0;
		this.previousBall = null;
		this.currentBall = null;
		this.interval = null;
		this.speechEnabled = true;
		this.selectedCaller = "1"; 
		this.enableCaller = true;
		this.fetchData = this.fetchData.bind(this);
		this.state = {
            income: null,
            pattern: null,
			isRegistered: null,
			cardNumber: '',
			GameType: "1", 
        };
		this.chimes = [
			{ label: "Chime 1", value: chime1 },
			{ label: "Chime 2", value: chime2 },
			{ label: "Chime 3", value: chime3 },
			{ label: "Chime 4", value: chime4 },
			{ label: "Chime 5", value: chime5 },
			{ label: "Chime 6", value: chime6 },
			{ label: "Chime 7", value: chime7 },
			{ label: "Chime 8", value: chime8 },
			{ label: "Chime 9", value: chime9 },
			{ label: "Chime 10", value: chime10 },
			{ label: "Chime 11", value: chime11 },
			{ label: "Chime 12", value: chime12 },
			{ label: "Pop 1", value: pop1 },
			{ label: "Pop 2", value: pop2 },
			{ label: "Pop 3", value: pop3 },
			{ label: "Pop 4", value: pop4 },
		];
		
		this.shuffleSound = shuffle;
		this.handleGameCheck = this.handleGameCheck.bind(this);
		// Patterns
		this.patternPlaceholder = "Choose a pattern";
		this.presets = getPresetPatterns();
     
		//this.GameType=localStorage.getItem("GameType");

		let gameData = JSON.parse(localStorage.getItem("lpb-gameData"));
		let gameState = JSON.parse(localStorage.getItem("lpb-gameState"));

		if (gameData && gameState) {
			for (let key in gameData) {
				this[key] = gameData[key];
			}
			this.state = gameState;
		} else {
			// Set initial state
			this.state = this.getInitialStateData();
		}
	}
	handleCheck = async () => {
		const currentState = this.state.showCheckModal;
		this.setState({ showCheckModal: !currentState }, () => {
			// Call fetchData after the state has updated
			this.fetchData();
		});
	};
	 // Handle Game Type Toggle
	 handleGameCheck(type) {
		const gameTypeValue = type === "Manual" ? "1" : "0";
	
		// Update state and localStorage
		this.setState({ GameType: gameTypeValue });
		localStorage.setItem("gametype", gameTypeValue);
	  }
	
	getInitialStateData() {
		return {
			board: generateBingoBoard(),
			previousCallList: [],
			displayBoardOnly: false,
			delay: 3000,
			running: false,
			enableCaller: true,
			skipUnused: true,
			wildBingo: false,
			evensOdds: false,
			doubleCall: false,
			extraTalk: true,
			chime: false,
			selectedChime: this.chimes[0],
			selectedCaller: null,
			showResetModal: false,
			showCheckModal: false,
			pattern:"1",
			GameType: "1"
		};
	}

	/**
	 * In case of going from one page to another, when we return
	 * and the component has mounted reinitialize the game from
	 * local storage.
	 *
	 */
	componentDidMount() {
		
		const storedIncome = localStorage.getItem("income");
		const storedPattern = localStorage.getItem("pattern");
		const storedGameType = localStorage.getItem("gametype");
		if (storedIncome) {
		  this.setState({ income: storedIncome });
		}
	
		if (storedPattern) {
		  this.setState({ pattern: storedPattern });
		}
		if (storedGameType) {
			
			this.setState({ GameType: storedGameType });
		  }
		// Optionally clear LocalStorage
		localStorage.removeItem("income");
		localStorage.removeItem("pattern");
	
		// Ensure the reset modal doesn't show at initial load
		this.setState({ showResetModal: false, showCheckModal: false });
	  }
	
    async initDB() {
        return openDB('audio-cache-db', 1, {
            upgrade(db) {
                db.createObjectStore('audios');
            }
        });
    }

    async storeAudio(url, blob) {
        const db = await this.dbPromise;
        const tx = db.transaction('audios', 'readwrite');
        tx.store.put(blob, url);
        await tx.done;
    }

    async getAudio(url) {
        const db = await this.dbPromise;
        return db.get('audios', url);
    }

// Play audio function
async playAudio(voicePath) {
	const audioUrl = voicePath;
	let blob = await this.getAudio(audioUrl); // Check if already stored

	if (!blob) {
		try {
			const response = await fetch(audioUrl); // Fetch from network
			if (!response.ok) throw new Error('Network response was not ok');
			blob = await response.blob(); // Convert to blob
			await this.storeAudio(audioUrl, blob); // Store in IndexedDB
		} catch (error) {
			console.error('Failed to fetch audio file:', error);
			return;
		}
	}

	const audio = new Audio(URL.createObjectURL(blob)); // Create audio object
	audio.preload = 'auto'; // Preload the audio
	audio.play().catch(error => console.error('Error playing audio:', error));
}

    voices = (rb) => {
        const selectvoice = document.getElementById("voiceselect").value; // Get the selected voice
       
        const voiceFiles = [
            '1.wav', '2.wav', '3.wav', '4.wav', '5.wav', '6.wav', '7.wav', '8.wav', '9.wav', '10.wav', 
            '11.wav', '12.wav', '13.wav', '14.wav', '15.wav', '16.wav', '17.wav', '18.wav', '19.wav', 
            '20.wav', '21.wav', '22.wav', '23.wav', '24.wav', '25.wav', '26.wav', '27.wav', '28.wav', 
            '29.wav', '30.wav', '31.wav', '32.wav', '33.wav', '34.wav', '35.wav', '36.wav', '37.wav', 
            '38.wav', '39.wav', '40.wav', '41.wav', '42.wav', '43.wav', '44.wav', '45.wav', '46.wav', 
            '47.wav', '48.wav', '49.wav', '50.wav', '51.wav', '52.wav', '53.wav', '54.wav', '55.wav', 
            '56.wav', '57.wav', '58.wav', '59.wav', '60.wav', '61.wav', '62.wav', '63.wav', '64.wav', 
            '65.wav', '66.wav', '67.wav', '68.wav', '69.wav', '70.wav', '71.wav', '72.wav', '73.wav', 
            '74.wav', '75.wav'
        ];
        const directories = [
           'voice/','nigus/', 'modern arada/','modern formal/', 'fana/', 'famharic/', 'foromifa/','Afan/', 'gual/', 'wedi/'
        ];

        const path = directories[selectvoice - 1];
        const voiceFile = voiceFiles[rb - 1];
    
        if (path && voiceFile) {
            this.playAudio(path + voiceFile);
			
        } else {
            console.error('Invalid voice selection or file index');
        }
    };

    handleChooseCaller = (event) => {
        const selectvoice = event.target.value;
        this.setState({ selectedCaller: selectvoice });
        
    };
	/**
	 * [componentDidUpdate description]
	 *
	 * @param   {[type]}  prevProps  [prevProps description]
	 * @param   {[type]}  state      [state description]
	 *
	 * @return  {[type]}             [return description]
	 */
	componentDidUpdate(prevProps, state) {
		let gameData = {
			totalBallsCalled: this.totalBallsCalled,
			previousBall: this.previousBall,
			currentBall: this.currentBall,
			interval: this.interval,
		};
		localStorage.setItem("lpb-gameData", JSON.stringify(gameData));
		localStorage.setItem("lpb-gameState", JSON.stringify(this.state));
	}

	/**
	 * [initializeFromLocalStorage description]
	 *
	 * @return  {[type]}  [return description]
	 */
	initializeFromLocalStorage = () => {
		let gameData = JSON.parse(localStorage.getItem("lpb-gameData"));
		let gameState = JSON.parse(localStorage.getItem("lpb-gameState"));
		if (gameData && gameState) {
			for (let key in gameData) {
				this[key] = gameData[key];
			}
			this.setState(...gameState);
		}
	};

	
	/**
	 * Handles the audible call of the ball
	 *
	 * @param   {Object}  ball  Object representing a ball
	 */
	
	startNewGame = () => {
		// Obtain all randomized balls
		let byteArray = new Uint8Array(1);
		let randomVals = [];

		while (randomVals.length < 75) {
			let randomVal = window.crypto.getRandomValues(byteArray)[0];
			if (randomVal > 0 && randomVal <= 75 && !randomVals.includes(randomVal)) {
				randomVals.push(randomVal);
			}
		}

	};

	startNewAutoplayGame = () => {
		this.startbingogame();
		if (this.state.wildBingo) {
			this.startNewGame();
			
		} else {
			if (this.state.enableCaller) {
				if (this.state.extraTalk) {
					
					window.setTimeout(() => {
						this.toggleGame();
					}, 2000);
				} else {
					this.toggleGame();
				}
			} else {
				this.toggleGame();
			}
		}
	};


	toggleGame = () => {
		let running = this.state.running;
		if (running === true) {
			clearInterval(this.interval);
		} else {
			this.callBingoNumber();
			this.interval = setInterval(this.callBingoNumber, this.state.delay);
		}
		this.setState({ running: !running });
	};

	toggleResetModal = () => {
		const currentState = this.state.showResetModal;
		this.setState({ showResetModal: !currentState });
	};
	toggleCheckModal = () => {
		this.setState({ isRegistered: null });
		const currentState = this.state.showCheckModal;
		this.setState({ showCheckModal: !currentState });
		
	};
	confirmResetGame = () => {
		// Clear out local storage
		localStorage.removeItem("lpb-gameData");
		localStorage.removeItem("lpb-gameState");
		  // Optionally clear local storage
		  localStorage.removeItem('income');
		  localStorage.removeItem('pattern');
		// reset everything with the board
		clearInterval(this.interval);
	
		this.totalBallsCalled = 0;
		this.previousBall = null;
		this.currentBall = null;
		this.state.previousCallList= [];
		this.setState({ board: generateBingoBoard(), wildBall: null, running: false, showResetModal: false, previousCallList: [] });
		window.location.href = '/RegisterGame';
	};
	GoodBingo = () => {
		let audio=null;
		audio = new Audio('fana/good.wav');
        audio.preload = 'auto'; // Preload the audio
        audio.play(); 
	};
	NotBingo = () => {
		let audio=null;
		audio = new Audio('fana/notgood.wav');
        audio.preload = 'auto'; // Preload the audio
        audio.play();  
    
	};
	startbingogame = () => {
		let audio=null;
		audio = new Audio('nigus/start.wav');
        audio.preload = 'auto'; // Preload the audio
        audio.play();  
	};
	callBingoNumber = () => {
		let totalBallsCalled = this.totalBallsCalled;
		let totalPossibleBalls = 75;
	
		if (totalBallsCalled < totalPossibleBalls) {
			let board = this.state.board;
			let currentBall = null;
			let previousBall = this.currentBall;
			let randomBingoNumber = getRandomBingoNumber();
			let callAgain = false;
			let updateState = false;
			let previousCallList = [...this.state.previousCallList];

			// Map through the letters on the board
			Object.keys(board).map((letter) => {
				// Map through each number 1-15 under each letter on the board
				board[letter].map((number) => {
					// automatically set the number as not active (this will clear any previously active numbers)
					number.active = false;
					// If this is the match to the random number we called, do logic
					if (number.number === randomBingoNumber) {
						// if the number was not called, do logic. Else call again
						if (!number.called) {
							// set to called and add to previously called numbers
							number.called = true;
							previousCallList.push(number);

							currentBall = number;
							// if we are skipping unused numbers, a pattern has been selected, and this letter is not in use, we want to call a new number when
							// we are done here.
						
								// increment the total balls called.
								totalBallsCalled++;
								// set ball to active since we won't be calling again
								number.active = true;

								
								// if caller is enabled AND chimes are enabled, wait a sec to trigger the voice
								// else just call the voice right away
							
									this.voices(randomBingoNumber);
									
							
							updateState = true;
							this.totalBallsCalled = totalBallsCalled;
						} else {
							// call again cause we got a ball we already called
							callAgain = true;
						}
					}
					return number;
				});
				return letter;
			});

			if (updateState) {
				this.previousBall = previousBall;
				this.currentBall = currentBall;
				this.setState({ board: board, previousCallList: previousCallList });
			}
			if (callAgain && totalBallsCalled < 75) {
				this.callBingoNumber();
			}
		} else {
			clearInterval(this.interval);
			this.totalBallsCalled = totalPossibleBalls;
			this.previousBall = this.currentBall;
			this.currentBall = null;
			this.setState({ running: false });
		}
	};

	shuffleBalls = () => {
		let balls = generateBingoBoard();
		let letters = ["B", "I", "N", "G", "O"];
		let sound = new Audio(this.shuffleSound);
		let duration = 1500;
		for (let i = 0; i <= duration; i++) {
			window.setTimeout(() => {
				if (i === 0) {
					sound.play();
				}
				if (i > 0 && i <= duration) {
					flashRandomBall();
					this.setState({ board: balls });
				}
				if (i === duration) {
					sound.pause();
					clearInterval(this.interval);
	
		this.totalBallsCalled = 0;
		this.previousBall = null;
		this.currentBall = null;
		this.state.previousCallList= [];
		this.setState({ board: generateBingoBoard(), wildBall: null, running: false, showResetModal: false, previousCallList: [] });

				}
			}, duration);
		}

		function flashRandomBall() {
			let randomLetter = letters[Math.floor(Math.random() * 5)];
			let randomNumber = Math.floor(Math.random() * 15);
			Object.keys(balls).forEach((letter) => {
				Object.values(balls[letter]).forEach((ball) => {
					if (ball.letter === randomLetter) {
						balls[randomLetter][randomNumber].active = !balls[randomLetter][randomNumber].active;
						balls[randomLetter][randomNumber].called = !balls[randomLetter][randomNumber].called;
					}
					return ball;
				});
			});
		}
	};

	/* ------------------ Handlers */
	handleDelayChange = (e) => {
		if (this.state.running === true) {
			clearInterval(this.interval);
			this.interval = setInterval(this.callBingoNumber, e);
		}
		this.setState({ delay: e });
	};

	handleCheckbox = (e) => {
		let gamemode = e.currentTarget.dataset.gamemode;
		switch (gamemode) {
			case "skip-unused":
				this.setState({ skipUnused: e.currentTarget.checked });
				break;
			case "enable-doublecall":
				this.setState({ doubleCall: e.currentTarget.checked });
				break;
			case "enable-extratalk":
				this.setState({ extraTalk: e.currentTarget.checked });
				break;
			case "wild-bingo":
				this.setState({ wildBingo: e.currentTarget.checked });
				break;
			case "evens-odds":
				this.setState({ evensOdds: e.currentTarget.checked });
				break;
			case "enable-caller":
				
				this.setState({ enableCaller: e.currentTarget.checked });
				break;
			case "display-board":
				if (e.currentTarget.checked && this.state.running) {
					clearInterval(this.interval);
				}
				this.setState({ displayBoardOnly: e.currentTarget.checked, running: false });
				break;
			case "enable-chime":
				this.setState({ chime: e.currentTarget.checked });
				break;
			default:
				break;
		}
	};



	/* ------------------- JSX Display Functions */

	/**
	 * Returns a JSX element to display the current ball
	 *
	 * @return  {JSX}  JSX Element
	 */
	get currentBallDisplay() {
		return this.currentBall !== null ? getBallDisplay(this.currentBall) : getLogoBallDisplay();
	}

	/**
	 * Get Number Display shown above the pattern display
	 *
	 * @return  {JSX}  html element
	 */
	get numberDisplay() {
		let numbers = this.totalBallsCalled.toString().split("");
		if (numbers.length === 1) {
			return (
				<div>
					<span>&nbsp;</span>
					<span>{numbers[0]}</span>
				</div>
			);
		} else {
			return numbers.map((number, index) => <span key={"numDisplay" + number + index}>{number}</span>);
		}
	}

	/**
	 * Get the current call display
	 *
	 * @return  {JSX}  html element
	 */
	get currentCallDisplay() {
		const currentCall = this.currentBall;
		if (currentCall) {
			let numbers = ["0"];
			if (Object.prototype.hasOwnProperty.call(currentCall, "number")) {
				numbers = currentCall.number.toString().split("");
			}
			if (numbers.length === 1) {
				return (
					<div>
						<span>&nbsp;</span>
						<span>{numbers[0]}</span>
					</div>
				);
			} else {
				return numbers.map((number, index) => <span key={"call" + number + index}>{number}</span>);
			}
		} else {
			return (
				<div>
					<span>&nbsp;</span>
					<span>&nbsp;</span>
				</div>
			);
		}
	}

	/**
	 * Get the previous call display
	 *
	 * @return  {JSX}  html element
	 */
	get previousCallDisplay() {
		const previousCall = this.previousBall;
		if (previousCall) {
			let numbers = ["0"];
			if (Object.prototype.hasOwnProperty.call(previousCall, "number")) {
				numbers = previousCall.number.toString().split("");
			}
			if (numbers.length === 1) {
				return (
					<div>
						<span>&nbsp;</span>
						<span>{numbers[0]}</span>
					</div>
				);
			} else {
				return numbers.map((number, index) => <span key={"call" + number + index}>{number}</span>);
			}
		} else {
			return (
				<div>
					<span>&nbsp;</span>
					<span>&nbsp;</span>
				</div>
			);
		}
	}

	/**
	 * Reset confirmation modal display
	 *
	 * @return  {[JSX]}  Return modal or empty div
	 */
	get resetConfirmationModalDisplay() {
		if (this.state.showResetModal === true) {
			return (
				<div>
					<div className="modal">
						<h4>Reset Game</h4>
						<p>Are you sure you want to reset the game?</p>
						<p className="red-text">
							This action <strong>cannot</strong> be undone.
						</p>
						<p>
							<button onClick={this.toggleResetModal}>Cancel</button>
							<button className="primaryBtn" onClick={this.confirmResetGame}>
								Confirm
							</button>
							
						</p>
					</div>
					<div
						className="modal-backdrop"
						onClick={(e) => {
							e.preventDefault();
						}}></div>
				</div>
			);
		} else {
			return null;
		}
	}
	
	async fetchData() {
		const userId = localStorage.getItem('userId');
	
		if (!userId) {
			console.error('User ID not found in local storage');
			this.setState({ isRegistered: null });
			return;
		}
	
		try {
			const response = await axios.get('https://hamsterbingo.com/api2/fetch_result_data.php', {
				params: { userId, cardNumber: this.state.cardNumber },
			});
	
			console.log("API response:", response.data);
	
			if (response.data.isregistered) {
				console.log("User is registered.");
				this.setState({ isRegistered: true });
			} else {
				console.log("User is not registered.");
				this.setState({ isRegistered: false });
			}
		} catch (error) {
			console.error('Error fetching data:', error.message);
			this.setState({ isRegistered: null });
		}
	}
	
	/**
	 * Reset confirmation modal display
	 *
	 * @return  {[JSX]}  Return modal or empty div
	 */
	get resetConfirmationModalDisplay1() {
		if (this.state.showCheckModal === true) {
			//alert(this.state.GameType);
			return (
				<div>
					<div className="modal" style={{ color: '#444', height: '600px', borderRadius: '5px', background: '#f0f0f0' }}>
					{this.state.isRegistered  &&
                (this.state.GameType === "1" ? (
					<BingoTable 
					previousCallList={this.state.previousCallList} 
					cardNumber={this.state.cardNumber} 
					selectedPattern={this.state.pattern}
				/>
                ) : (
                    <BingoTable1 
								previousCallList={this.state.previousCallList} 
								cardNumber={this.state.cardNumber} 
								selectedPattern={this.state.pattern}
							/>
                ))}
						{this.state.isRegistered === false && (
							<div>You are not registered to play this game.</div>
						)}
						{this.state.isRegistered === null && (
							<div style={{
								fontSize: '20px',
								color: '#333',
								textAlign: 'center',
								padding: '20px',
								backgroundColor: '#f0f0f0',
								border: '1px solid #ccc',
								borderRadius: '5px'
							}}>
								Loading ......
							</div>
							
						)}
				
							{(this.state.GameType === "1" ? (
				<p>
				<button onClick={this.toggleCheckModal}>Cancel</button>
        <button className="primaryBtn" onClick={this.GoodBingo}>
            Good Bingo
        </button>
        <button className="primaryBtn" onClick={this.NotBingo}>
            Not Bingo
        </button>
		</p>
): (
	<p>
	<button onClick={this.toggleCheckModal}>Cancel</button>

</p>
))}

						
					</div>
					<div
						className="modal-backdrop"
						onClick={(e) => {
							e.preventDefault();
						}}></div>
				</div>
			);
		} else {
			return null;
		}
	}
	
	Notregistered = () => {
		const audio = new Audio('fana wend/notreg.wav');
		audio.preload = 'auto';
		audio.play();
	};
	
	render() {
		
		if (this.state.isRegistered===false && this.state.showCheckModal) {
			this.Notregistered();
			
		}
		const { GameType } = this.state;
		return (
			<div className="dark-bg light-links">
				{/* ----------- Bingo Board ------------- */}
				<section className="board-block">
					<div className="container row no-wrap align-stretch">
						{/* ------ Board ------- */}
						<div className="col pattern-side shrink padding-xlg">
							{/* -------- Digital Displays --------- */}
							<div className="row no-wrap margin-bottom-lg justify-space-between white-text">
								<div className="col text-center margin-sm">
									<div className="callNumber notranslate">{this.numberDisplay}</div>
									<div className="callNumber-text uppercase">Total Calls</div>
								</div>
								<div className="col text-center margin-sm">
									<div className="callNumber notranslate">{this.previousCallDisplay}</div>
									<div className="callNumber-text uppercase">Previous Call</div>
								</div>
							</div>

							{/* -------- Pattern --------- */}
							<Mypattern  pattern={this.state.pattern}  />
							<div style={{ display: 'flex', marginTop:'10px'}}>
								
							{this.resetConfirmationModalDisplay1}
      <input
        type="text"
        placeholder="Enter Card Number"
        value={this.state.cardNumber}
		onChange={(e) => this.setState({ cardNumber: e.target.value })}
        required
        style={{
          width: '180px',
          height: '40px',
          letterSpacing: '1px',
         
        }}
      />
      <button
	  
        type="button"
        className="btnbingo"
        style={{ marginLeft: '10px',width:'60px' }}
        onClick={this.handleCheck}
      >
        Check
      </button>
    </div>
						</div>
						<div className="col board-side">
							<BingoBoard board={this.state.board} manualMode={this.state.displayBoardOnly} manualCall={this.manualCall} />
						</div>
					</div>
				</section>

				{/* ----------- BOTTOM SECTION ------------- */}
				<section className="game-controls dark-bg">
					<div className="container row justify-start align-start">
						{/* ----------- Current Ball Display ------------- */}
						<div className="col min-size-350 padding-vertical-sm padding-horizontal-sm notranslate">
							{this.currentBallDisplay}

						</div>

						{/* ----------- Gameplay Controls ------------- */}
						<div 
                        className="col padding-vertical-sm padding-horizontal-sm"
                         style={{ margin: '0 auto', width: '20%',justifyContent:'start',alignItems:'start',marginLeft:'-150px' }}
                         >
							<section className="gameplay-controls">
								<div data-disabled={this.totalBallsCalled >= 75}>
											{/* ----------- Settings when using generation ---------- */}
								<div data-visibility={this.state.displayBoardOnly === false ? "show" : "hide"}>
									{/* ----------- Autoplay Settings ---------- */}
									<div className="row no-wrap align-start justify-start">
									
										<div className="col  text-center padding-vertical-sm padding-horizontal-sm">
											<div className="row no-wrap align-center slider" data-disabled={this.state.displayBoardOnly}>
												<div className="col shrink padding-right-md white-text">Slower</div>
												<div className="col">
													<Slider
														min={3000}
														max={20000}
														step={1000}
														value={this.state.delay}
														onChange={this.handleDelayChange}
														reverse={true}
													/>
												</div>
												<div className="col shrink padding-left-md white-text">Faster</div>
											</div>
										</div>
									</div>

								
								{/* ----------- Caller Selection ----------- */}
								<div
                className="row align-start justify-start"
                data-visibility={this.state.speechEnabled && this.state.enableCaller ? "show" : "show"}
            >
                <div className="col grow min-size-100 padding-horizontal-sm">
                    <select
                        id="voiceselect"
						style={{ height: '40px', width: '80%', marginLeft: '10%' }}
                        value={this.state.selectedCaller}
                        onChange={this.handleChooseCaller}
                    >
                        <option value="1">bereket</option>
                        <option value="2">Nigus</option>
                        <option value="3">arada</option>
                        <option value="4">Arada formal</option>
                        <option value="5">Amharic yordi</option>
                        <option value="6">Amharic Beti</option>
                        <option value="7">Oromifa Girl1</option>
						<option value="8">Oromifa Girl2</option>
                        <option value="9">Tigrigna Female</option>
                        <option value="10">Tigrigna male</option>
                    </select>
                </div>
            </div>
								</div>

									<button
										data-disabled={this.state.displayBoardOnly}
										data-newgame={this.totalBallsCalled === 0}
										className={this.state.running ? "pause-button" : "play-button"}
										onClick={this.totalBallsCalled === 0 ? this.startNewAutoplayGame : this.toggleGame}>
										{this.state.running ? "Pause Autoplay" : "Start Autoplay"}
									</button>
								</div>

								<button onClick={this.toggleResetModal} disabled={ this.totalBallsCalled === 0}>
									Reset Board
								</button>

								<button onClick={this.shuffleBalls} disabled={this.state.running || this.totalBallsCalled > 0}>
									Shuffle Board
								</button>
								<div className="toggle-container">
          <label className="container-game">
            Manual
            <input
              type="checkbox"
              checked={GameType === "1"} // Check if Manual is selected
              onChange={() => this.handleGameCheck("Manual")}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container-game">
            Automatic
            <input
              type="checkbox"
              checked={GameType === "0"} // Check if Automatic is selected
              onChange={() => this.handleGameCheck("Automatic")}
            />
            <span className="checkmark"></span>
          </label>
        </div>
							</section>
							{this.resetConfirmationModalDisplay}
						</div>

						<div className="col grow  padding-vertical-sm   white-text">
							<section className="game-settings">
	
							<CallHistory calledBalls={this.state.previousCallList}></CallHistory>
                            
							
							
							</section>
						</div>

						{/* ----------- Game Settings ------------- */}
						<div className="col   padding-vertical-sm   white-text"
						 style={{justifyContent:'start',alignItems:'start',width:'100%' }}
						 >
							<section className="game-settings">
							
							<img src="derash.png" alt="Logo" style={{ width:'100%' }} />
						<p style={{ fontSize: '4rem',fontWeight:'700',marginTop:'-5px'}}
						>{this.state.income} ብር</p>
							</section>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default BingoGame;
