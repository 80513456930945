import React, { useState, useEffect } from 'react';
import "../sass/report.scss";
import Header from './Header'; // Import the Header component

const Control = () => {
   const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [data, setData] = useState(null); // For storing report data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchReport = async () => {
    setLoading(true);
    setError('');
    setData(null); // Clear previous data

    const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage

    if (!userId) {
      setError('User ID not found. Please ensure you are logged in.');
      setLoading(false);
      return;
    }

    const url = `https://hamsterbingo.com/api2/control.php?id=${userId}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          date_r: date,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data. Please try again later.');
      }

      const result = await response.json();

      if (result.error) {
        throw new Error(result.error);
      }

      setData(result); // Set the fetched data
      console.log(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []); // Empty dependency array to run only once

  return (
    <div>
      <Header /> {/* Use the Header component */}
      <h1>Generate Report</h1>
      <div className="report-container">
        <form
          className="report-form"
          onSubmit={(e) => {
            e.preventDefault();
            fetchReport();
          }}
        >
          <div className="form-group">
            <label htmlFor="date">To Date:</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Fetch Report'}
          </button>
        </form>

        {loading && <p>Loading...</p>}
        {error && <p style={{ color: 'red' }}>Error: {error}</p>}

        {data && (
          <div>
            <table className="report-table">
              <thead>
                <tr>
                  <th>Round</th>
                  <th>Price</th>
                  <th>No. Player</th>
                  <th>Winner Price</th>
                  <th>Income</th>
                </tr>
              </thead>
              <tbody>
                {data.playRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{record.price}</td>
                    <td>{record.sew}</td>
                    <td>{record.winnerPrice}</td>
                    <td>{record.income}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="4" style={{ textAlign: "right" }}>Total Income:</td>
                  <td>{data.totalIncome}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Control;
