import React, { useState, useEffect } from 'react';
import '../sass/BingoTable.css';
import axios from 'axios';

const BingoTable = ({ previousCallList, cardNumber }) => {
    const [columnValues, setColumnValues] = useState([]);
    const [matchedValues, setMatchedValues] = useState([0, 'free', 'FREE']);
    const [tableData, setTableData] = useState([]);
    const [matchedRows, setMatchedRows] = useState(Array(5).fill(false));
    const [matchedCols, setMatchedCols] = useState(Array(5).fill(false));
    const [matchedDiagonal1, setMatchedDiagonal1] = useState(false);
    const [matchedDiagonal2, setMatchedDiagonal2] = useState(false);
    const [matchedCorners, setMatchedCorners] = useState({
        topLeft: false,
        topRight: false,
        bottomLeft: false,
        bottomRight: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage

            try {
                const response = await axios.get('https://hamsterbingo.com/api2/fetch_result_data.php', {
                    params: { userId, cardNumber },
                });
                if (response.data.isregistered) {
                    const fetchedColumnValues = response.data.columnValues.map((col) => [...col]);

                    // Set the center cell to 'Free' if the data structure is 5x5
                    if (fetchedColumnValues.length === 5 && fetchedColumnValues[2].length === 5) {
                        fetchedColumnValues[2][2] = 'free';
                    }
                    setColumnValues(fetchedColumnValues);
                    
                } else {
                    let audio=null;
        audio = new Audio('fana wend/notreg.wav');
        audio.preload = 'auto'; // Preload the audio
        audio.play();  
        
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, [cardNumber]);

    useEffect(() => {
        const matchedNumbers = previousCallList.map(item => String(item.number));

        setMatchedValues([0, 'free', 'FREE', ...matchedNumbers]);

        const updatedTable = [];
        const updatedRowMatches = Array(5).fill(true);
        const updatedColMatches = Array(5).fill(true);
        const updatedCorners = { ...matchedCorners };
        const diagonal1Matches = Array(5).fill(false);
        const diagonal2Matches = Array(5).fill(false);

        for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
            const row = [];
            for (let colIndex = 0; colIndex < 5; colIndex++) {
                const cell = columnValues[colIndex]?.[rowIndex];
                const isMatched = matchedValues.includes(cell);

                row.push({ cell, isMatched });

                if (!isMatched) updatedRowMatches[rowIndex] = false;
                if (!isMatched) updatedColMatches[colIndex] = false;

                if (rowIndex === colIndex && isMatched) diagonal1Matches[rowIndex] = true;
                if (rowIndex + colIndex === 4 && isMatched) diagonal2Matches[rowIndex] = true;

                if (rowIndex === 0 && colIndex === 0 && isMatched) updatedCorners.topLeft = true;
                if (rowIndex === 0 && colIndex === 4 && isMatched) updatedCorners.topRight = true;
                if (rowIndex === 4 && colIndex === 0 && isMatched) updatedCorners.bottomLeft = true;
                if (rowIndex === 4 && colIndex === 4 && isMatched) updatedCorners.bottomRight = true;
            }
            updatedTable.push(row);
        }

        setTableData(updatedTable);
        setMatchedRows(updatedRowMatches);
        setMatchedCols(updatedColMatches);
        setMatchedDiagonal1(diagonal1Matches.every(Boolean));
        setMatchedDiagonal2(diagonal2Matches.every(Boolean));
        setMatchedCorners(updatedCorners);
    }, [columnValues, previousCallList]);

    return (
        <div>
            <h4 style={{ fontSize: 42, fontWeight: 'bolder', textAlign: 'center', backgroundColor: '#0047BB', color: 'white', marginTop: '-30px' }}>
                Card No: {cardNumber}
            </h4>
            <table style={{ width: '96%', height: '450px', marginLeft: '2%', position: 'relative', textAlign: 'center', marginTop: '-40px' }}>
                <thead>
                    <tr>
                        <th className='b'>B</th>
                        <th className='i'>I</th>
                        <th className='n'>N</th>
                        <th className='g'>G</th>
                        <th className='o'>O</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cellData, colIndex) => {
                                const isCorner =
                                    (rowIndex === 0 && colIndex === 0 && matchedCorners.topLeft) ||
                                    (rowIndex === 0 && colIndex === 4 && matchedCorners.topRight) ||
                                    (rowIndex === 4 && colIndex === 0 && matchedCorners.bottomLeft) ||
                                    (rowIndex === 4 && colIndex === 4 && matchedCorners.bottomRight);

                                const isRowMatched = matchedRows[rowIndex];
                                const isColMatched = matchedCols[colIndex];
                                const isDiag1Matched = matchedDiagonal1 && rowIndex === colIndex;
                                const isDiag2Matched = matchedDiagonal2 && rowIndex + colIndex === 4;

                                let cellClass = '';
                                if (isCorner && matchedCorners.topLeft && matchedCorners.topRight && matchedCorners.bottomLeft && matchedCorners.bottomRight) {
                                    cellClass = 'matched-corner';
                                } else if (isRowMatched) {
                                    cellClass = 'matched-row';
                                } else if (isColMatched) {
                                    cellClass = 'matched-col';
                                } else if (isDiag1Matched || isDiag2Matched) {
                                    cellClass = 'matched-diag';
                                } else if (cellData.isMatched) {
                                    cellClass = 'matched';
                                }

                                return (
                                    <td
                                        key={`${rowIndex}-${colIndex}`}
                                        className={`${cellClass} ${cellData.cell === 'free' ? 'free' : ''}`}
                                    >
                                        {cellData.cell === 'free' ? 'free' : cellData.cell}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BingoTable;
